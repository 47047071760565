<template>
  <div class="page-wrapper">
    <div class="page-info">
      <div class="page-top-info">
        <el-row class="page-top-line">
          <el-col class="item" :span="6">
            <div class="inner">
              <div class="label">班主任姓名</div>
              <div class="content">{{ dataCont.teacher }}</div>
            </div>
          </el-col>
          <el-col class="item" :span="6">
            <div class="inner">
              <div class="label">行政班</div>
              <div class="content">{{ dataCont.class }}</div>
            </div>
          </el-col>
          <el-col class="item" :span="6">
            <div class="inner">
              <div class="label">年级</div>
              <div class="content">{{ dataCont.grade }}</div>
            </div>
          </el-col>
          <el-col class="item" :span="6">
            <div class="inner">
              <div class="label">校区</div>
              <div class="content">{{ dataCont.school }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="page-card">
        <div class="page-card-title">
          <div class="l"><img src="../../../../assets/img/class-report-icon.png" alt="" />本周工作总结和反思</div>
          <div class="r">提交时间 {{ dataCont.created_at }}</div>
        </div>
        <div class="page-card-content">
          <div class="inner-box">
            {{ dataCont.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="page-tool">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  name: 'Details',
  data() {
    return {
      dataCont: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2.get('/api/teacher/class-week-report/details?id=' + this.$route.query.id).then(res => {
        this.dataCont = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
